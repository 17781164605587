import React, { useRef, useState, useEffect, useCallback } from "react";
import SearchBar from "./components/SearchBar"
import random from "random"
import str from "randomstring"

interface account {
    address: string,
    password: string,
}

interface messageListItem {
    id: string,
    from: {
        address: string,
        name: string,
    }
    subject: string,
    intro: string,
    createdAt: string,
}

interface message extends messageListItem {
    html: string[],
}

export const App = () => {
    const domain = "yourtempmail.xyz"
    const API_BASE = "https://api.mail.tm"
    const [query, setQuery] = useState("");
    const divRef = useRef<any>(null);
    const formRef = useRef<any>(null);
    const [account, setAccount] = useState<account | undefined>()
    const [JWT, setJWT] = useState<string | undefined>()
    const [messages, setMessages] = useState<messageListItem[] | undefined>()
    const [message, setMessage] = useState<message | undefined>()
    const [isFetchingMessages, setIsFetchingMessages] = useState(false)

    const handleQueryChange = (query: any) => {
        setQuery(query)
    }

    const setAccountState = useCallback(async (force: any) => {
        if (!account || force) {
            let address: any = ''
            let password: any = ''
            if (!force) {
                address = localStorage.getItem('mailAddress')
                password = localStorage.getItem('mailPassword')
            }
            if (!address) address = str.generate(random.int(10, 20)) + "@solarunited.net".toLowerCase()
            localStorage.setItem('mailAddress', address.toLowerCase())
            if (!password) password = str.generate(random.int(10, 20)).toLowerCase()
            localStorage.setItem('mailPassword', password.toLowerCase())
            setAccount({
                address: address,
                password: password,
            })
        }
    }, [account])

    const fetchMessage = useCallback(async (id) => {
        setIsFetchingMessages(true)
        let token: any = JWT;
        if (!token) {
            token = localStorage.getItem('mailJWT');
            if (token) {
                setJWT(token)
            }
        }
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `bearer ${token}`);
        await fetch(`${API_BASE}/messages/${id}`, {
            method: 'GET',
            redirect: 'follow',
            headers: headers,
        }).then(response => response.json())
            .then(result => {
                console.log(result, [localStorage.getItem('mailAddress'), localStorage.getItem('mailPassword')], JWT)
                if (result) {
                    setMessage(result);
                }
                setIsFetchingMessages(false)
            })
            .catch(error => console.log('error', error));
    }, [JWT])

    const fetchMessages = useCallback(async () => {
        setMessage(undefined)
        setIsFetchingMessages(true)
        let token: any = JWT;
        if (!token) {
            token = localStorage.getItem('mailJWT');
            if (token) {
                setJWT(token)
            }
        }
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `bearer ${token}`);
        await fetch(`${API_BASE}/messages`, {
            method: 'GET',
            redirect: 'follow',
            headers: headers,
        }).then(response => response.json())
            .then(result => {
                console.log(result["hydra:member"], [localStorage.getItem('mailAddress'), localStorage.getItem('mailPassword')], JWT)
                if (result.code === 401) {
                    setTimeout(fetchMessages, 500);
                } else if (result["hydra:member"]) {
                    setMessages(result["hydra:member"]);
                }
                setIsFetchingMessages(false)
            })
            .catch(error => console.log('error', error));
    }, [JWT])

    const fetchJWTToken = useCallback(async () => {
        let m = localStorage.getItem('mailAddress');
        let p = localStorage.getItem('mailPassword')
        if (m && p) {
            setAccount({
                address: m.toLowerCase(),
                password: p.toLowerCase(),
            })
            const headers = new Headers();
            headers.append("Content-Type", "application/json");
            console.log(localStorage.getItem('mailAddress')?.toLowerCase())
            await fetch(`${API_BASE}/token`, {
                method: 'POST',
                redirect: 'follow',
                headers: headers,
                body: JSON.stringify({
                    address: localStorage.getItem('mailAddress')?.toLowerCase(),
                    password: localStorage.getItem('mailPassword')?.toLowerCase()
                }),
            }).then(response => response.json())
                .then(result => {
                    console.log(result, [localStorage.getItem('mailAddress'), localStorage.getItem('mailPassword')])
                    if (result.code === 401 || result.status === 400) {
                        setTimeout(fetchJWTToken, 500);
                    } else if (result.token) {
                        localStorage.setItem('mailJWT', result.token)
                        setJWT(result.token)
                        setTimeout(fetchMessages, 500);
                    }
                })
                .catch(error => console.log('error', error))
        } else {
            setAccountState(false)
            setTimeout(fetchJWTToken, 500);
        }

    }, [fetchMessages, setAccountState])

    const createMailAccount = useCallback(async (recreate: boolean) => {
        if (recreate) {
            localStorage.clear()
            setIsFetchingMessages(true);
            setTimeout(async () => {
                console.log('cleared')
                await setAccountState(recreate)
            }, 1000)
        } else {
            await setAccountState(recreate)
        }
        setMessages(undefined)
        setMessage(undefined)
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        await fetch(`${API_BASE}/accounts`, {
            method: 'POST',
            redirect: 'follow',
            headers: headers,
            body: JSON.stringify({
                address: localStorage.getItem('mailAddress')?.toLowerCase(),
                password: localStorage.getItem('mailPassword')?.toLowerCase()
            }),
        }).then(response => response.json())
            .then(result => {
                console.log(result);
                setTimeout(fetchJWTToken, 500);
            })
            .catch(error => console.log('error', error));
    }, [fetchJWTToken, setAccountState])

    const getMessageHTML = (html: any) => {
        return { __html: html }
    }

    const resetState = () => {
        localStorage.clear()
        setIsFetchingMessages(false);
        setAccount(undefined)
        setJWT(undefined)
        setMessages(undefined)
        setMessage(undefined)
    }

    useEffect(() => {
        let address = localStorage.getItem('mailAddress')?.toLowerCase()
        let password = localStorage.getItem('mailPassword')?.toLowerCase()
        if (address && password) {
            setAccountState(false)
        }
        if (address && password && JWT) {
            fetchMessages()
        }
    }, [setAccountState, fetchMessages, JWT]);

    return (
        <div className="w-screen h-screen bg-opacity-50 bg-center bg-no-repeat bg-cover bg-main">
            <div ref={divRef} className={`w-screen h-screen flex flex-col items-center justify-start z-50`}>
                <div className="w-full max-w-5xl p-3 mt-4 bg-white rounded-lg shadow-2xl">
                    <div className={`text-base font-light text-center text-gray-900`}>
                        {account ?
                            <>
                                <div className="flex flex-row items-center w-full pb-2 mb-2 border-b">
                                    <span className="font-semibold text-gray-900">{account.address?.toLowerCase()}</span>
                                    <button className="px-5 py-1 ml-2 overflow-hidden font-bold text-gray-900 transition-all bg-gray-300 rounded hover:bg-gray-600 hover:text-gray-200" onClick={() => fetchMessages()}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className={`w-4 h-4 ${isFetchingMessages ? 'animate-spin' : ''}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                                        </svg>
                                    </button>
                                    <button className="px-5 py-1 ml-auto text-xs font-bold text-red-900 transition-all bg-red-100 rounded hover:bg-red-500 hover:text-red-100" onClick={() => resetState()}>Delete Address</button>
                                </div>
                                {messages && messages.length > 0 && !isFetchingMessages ?
                                    <div>
                                        {message ?
                                            <div className="flex flex-col justify-start">
                                                <div className="flex justify-start">
                                                    <div className="text-left">
                                                        <div className="font-semibold text-gray-800">{message.subject}</div>
                                                        <div className="flex-shrink-0 overflow-hidden text-xs overflow-ellipsis font-extralight">
                                                            <div className="font-light">{message.from.name}</div>
                                                            {message.from.address}
                                                        </div>
                                                    </div>
                                                    <button className="px-5 py-1 ml-auto text-xs font-bold text-gray-900 transition-all bg-gray-300 rounded hover:bg-gray-600 hover:text-gray-200" onClick={() => setMessage(undefined)}>Back</button>
                                                </div>
                                                <div className="p-5 mt-2 overflow-x-scroll text-left border rounded max-h-64">
                                                    <span dangerouslySetInnerHTML={getMessageHTML(message.html)} />
                                                </div>
                                            </div>
                                            :
                                            <div>
                                                {messages.slice(0, 6).map((message, i) => {
                                                    return <div key={i} onClick={() => fetchMessage(message.id)} className={`${i % 2 === 0 ? 'bg-gray-100' : ''} flex items-center rounded p-1 hover:bg-gray-200 transition-all cursor-pointer`}>
                                                        <div className="flex-shrink-0 mr-5 text-sm italic font-extralight">{new Intl.DateTimeFormat('en-US', { dateStyle: 'medium' }).format(new Date(message.createdAt))}</div>
                                                        <div className="flex-shrink-0 mr-5 overflow-hidden text-sm text-left w-36 overflow-ellipsis"><div className="font-semibold">{message.from.name}</div>{message.from.address}</div>
                                                        <div className="overflow-hidden text-sm whitespace-nowrap overflow-ellipsis">{message.intro}</div>
                                                    </div>
                                                })

                                                }
                                            </div>
                                        }
                                    </div>
                                    :
                                    <div>{isFetchingMessages ? 'Loading' : 'No messages'}</div>
                                }
                            </>
                            :
                            <button className="px-5 py-1 font-bold text-gray-900 transition-all bg-gray-300 rounded hover:bg-gray-600 hover:text-gray-200" onClick={() => createMailAccount(false)}>Create a Temporary Email Address</button>
                        }
                    </div>
                </div>
                <form ref={formRef} className="absolute z-10 w-full px-5 transform -translate-y-1/2 top-1/2" action={`https://${domain}/s`} method="GET">
                    <div className="relative flex flex-row w-full max-w-5xl mx-auto transition-all bg-white rounded-lg hover:shadow-2xl focus-within:shadow-2xl ring-1 ring-gray-500 focus-within:ring-gray-700">
                        <SearchBar formRef={formRef} query={query} setQuery={handleQueryChange} />
                        <button className="flex flex-col items-center justify-center w-24 p-2 transition-all rounded-lg hover:bg-gray-100 active:outline-none focus:outline-none" type="submit">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                            </svg>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
};
